<template>
	<div class="container">
		<div class="user-nav">
			<Search :isClass="false" :isSearch="false" :isBtn="false" :termlist='termlist' ref="search"
				@handleChange="handleChange" />
		</div>
		<div class="main">
			<div class="table-box">
				<h4>思想品德</h4>
				<table border="1" cellspacing="0" rules="all">
					<tr>
						<th>项目</th>
						<th>写实记录</th>
						<th>荣誉记录</th>
						<th>违纪记录</th>
						<th>总计</th>
					</tr>
					<tr>
						<td>数据条数</td>
						<td>{{moralityForm.realyCount}}</td>
						<td>{{moralityForm.honorCount}}</td>
						<td>{{moralityForm.breakCount}}</td>
						<td>{{moralityForm.sumCount}}</td>
					</tr>
					<tr>
						<td>综素成绩（分）</td>
						<td>{{moralityForm.realyScore}}</td>
						<td>{{moralityForm.honorScore}}</td>
						<td>{{moralityForm.breakRemainIndexScore}}</td>
						<td>{{moralityForm.sumScore}}</td>
					</tr>
				</table>
			</div>
			<div class="more">
				<div class="record-title">
					<h4>相关记录</h4>
					<!-- <el-link :underline="false" type="primary" @click="uploadMyHonor">上传个人荣誉</el-link> -->
				</div>
				<el-row type="flex" justify="space-between" class="more-tit">
					<el-col :span="12">
						<h5>荣誉记录</h5>
					</el-col>
					<el-col :span="12" style="text-align: right" v-if="curRole == 'student'">
						<el-button @click="uploadMyHonor" type="primary" plain><i class="el-icon-circle-plus"></i>
							添加个人荣誉</el-button>
					</el-col>
				</el-row>
				<el-table :data="honorlist" style="width: 100%" border :header-cell-style="tableHeaderColor"
					:cell-style="cellStyleFun">
					<el-table-column type="index" label="序号" width="50">
					</el-table-column>
					<el-table-column prop="honorName" label="荣誉名称"> </el-table-column>
					<el-table-column prop="honorType" label="荣誉类别" width="180">
					</el-table-column>
					<el-table-column prop="indexRuleLevel" label="级别" width="120">
					</el-table-column>
					<el-table-column prop="createTime" label="发布时间" width="120">
					</el-table-column>
					<el-table-column prop="_auditStatus" label="审核状态" width="120">
					</el-table-column>
					<el-table-column label="操作" width="100">
						<template slot-scope="scope">
							<el-button @click="handleDetailHonor(scope.row)" type="text" size="small">查看</el-button>
						</template>
					</el-table-column>
				</el-table>

				<el-row type="flex" justify="space-between" class="more-tit more-tittop">
					<el-col :span="12">
						<h5>违纪记录</h5>
					</el-col>
					<el-col :span="12" style="text-align: right"> </el-col>
				</el-row>
				<el-table :data="weiJilist" style="width: 100%" border :header-cell-style="tableHeaderColor"
					:cell-style="cellStyleFun">
					<el-table-column type="index" label="序号" width="50">
					</el-table-column>
					<el-table-column prop="breakContext" label="违纪内容"> </el-table-column>
					<el-table-column prop="breakScore" label="扣分值" width="180">
					</el-table-column>
					<el-table-column prop="createTime" label="发布时间" width="120">
					</el-table-column>
					<el-table-column prop="breakStatus" label="状态" width="120">
					</el-table-column>
					<el-table-column label="操作" width="100">
						<template slot-scope="scope">
							<el-button @click="handleDetailWeiji(scope.row)" type="text" size="small">查看</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>

		<!-- 查看详情 -->
		<el-dialog :title="title" :visible.sync="open" width="600px" center>
			<el-form ref="form" :model="form" style="
          border-top: 1px solid #e1e3e9;
          border-bottom: 1px solid #e1e3e9;
          padding-top: 5px;
        ">
				<el-form-item style="border-bottom: 1px dashed #e1e3e9">
					<span class="sname">{{form.userName}}</span>
					<span class="snumb">学籍号：{{form.studentCode}}</span>
				</el-form-item>
				<!-- 违纪 -->
				<template v-if="!isHonor">
					<el-form-item label="违纪内容：">
						<span class="scontent">{{form.breakContext}}</span>
					</el-form-item>
					<el-form-item label="扣分值：">
						<span class="scontent">{{form.breakScore}}</span>
					</el-form-item>
					<el-form-item label="发布时间：">
						<span class="scontent">{{form.createTime}}</span>
					</el-form-item>
					<el-form-item label="状态：" style="border-bottom: 1px dashed #E1E3E9">
						<span style="color: red">{{form.breakStatus}}</span>
					</el-form-item>
				</template>
				<!-- 荣誉 -->
				<template v-if="isHonor">
					<el-form-item label="荣誉名称：">
						<span class="scontent">{{form.honorName}}</span>
					</el-form-item>
					<el-form-item label="级别：">
						<span class="scontent">{{form.indexRuleLevel}}</span>
					</el-form-item>
					<el-form-item label="发布时间：" style="border-bottom: 1px dashed #E1E3E9">
						<span class="scontent">{{form.createTime}}</span>
					</el-form-item>
					<el-form-item label="获奖等级：">
						<span class="scontent">{{form.resultLevel}}</span>
					</el-form-item>
				</template>
				<el-form-item label="所在学期：">
					<span class="scontent">{{form.termName}}</span>
				</el-form-item>
				<el-form-item label="颁发单位：" v-if="isHonor">
					<span class="scontent">{{form.awardedUnit}}</span>
				</el-form-item>
				<!-- <el-form-item label="所在学校：" v-if="!isHonor">
          <span class="scontent">信德中学</span>
        </el-form-item> -->
				<el-form-item label="佐证材料：" v-if="form._showImgList">
					<el-image v-for="(item, index) in form._imgFileList" style="width: 100px; height: 100px;"
						:preview-src-list="form._imgFileList" :key="index" :src="item"></el-image>
				</el-form-item>
				<el-form-item label="佐证材料：" v-if="form._showOtherFileList">
					<a v-for="(item, idx) in form._otherFileList" :key="item" target="_blank" :download="'附件' + idx"
						:href="item">附件{{ idx + 1 }} </a>
				</el-form-item>
				<el-form-item label="备注说明：">
					<span class="scontent">{{form.bz}}</span>
				</el-form-item>
				<el-form-item label="审核状态：" v-if="isHonor">
					<span class="scontent">{{ aStatus(form.auditStatus) }}</span>
				</el-form-item>
				<el-form-item label="不通过原因：" v-if="form.auditStatus == 2">
					<span class="scontent">{{ form.causeDes }}</span>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancel">关 闭</el-button>
				<el-button type="primary" @click="reupload"
					v-if="form.auditStatus == 0 || form.auditStatus == 2">重新编辑上传</el-button>
			</span>
		</el-dialog>
		<!-- 个人荣誉记录 -->
		<el-dialog title="荣誉记录" :visible.sync="dialog.addMyHonor" width="600px" center>
			<el-form :model="addMyHonorForm" :rules="addMyHonorFormRules" ref="addMyHonorForm">
				<el-form-item label="学生姓名：" prop="userName">
					<span>{{ addMyHonorForm.userName }}</span>
				</el-form-item>
				<el-form-item label="学籍号：" prop="studentCode">
					<span>{{ addMyHonorForm.studentCode }}</span>
				</el-form-item>
				<el-form-item label="荣誉名称：" prop="honorName">
					<el-input style="width: 300px;" placeholder="请输入荣誉名称" v-model="addMyHonorForm.honorName"></el-input>
				</el-form-item>
				<el-form-item label="荣誉级别：" prop="indexRuleLevel">
					<el-select style="width: 300px;" v-model="addMyHonorForm.indexRuleLevel" placeholder="请选择荣誉级别">
						<el-option v-for="(item, index) in constData.honorLvlList" :key="index" :label="item.name"
							:value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="获奖等级：" prop="resultLevel">
					<el-input style="width: 300px;" placeholder="请输入获奖等级" v-model="addMyHonorForm.resultLevel"></el-input>
				</el-form-item>
				<el-form-item label="颁发单位：" prop="awardedUnit">
					<el-input style="width: 300px;" placeholder="请输入颁发单位" v-model="addMyHonorForm.awardedUnit"></el-input>
				</el-form-item>
				<el-form-item label="所在学期：">
					<span>{{ addMyHonorForm.termName }}</span>
				</el-form-item>
				<el-form-item label="佐证材料：" prop="file">
					<el-upload :action="upload.action" :accept="upload.accept" :limit="upload.limit" :multiple="upload.multiple"
						:file-list="upload.list" :on-change="uploadChange" :on-remove="uploadRemove" :auto-upload="upload.auto">
						<el-button size="small" type="primary" v-if="upload.list.length < 3">添加附件</el-button>
					</el-upload>
				</el-form-item>
				<el-form-item label="备注说明：" prop="bz">
					<el-input style="width: 300px;" type="textarea" v-model="addMyHonorForm.bz" placeholder="情况说明,选填"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="closeDialog('addMyHonor')">取 消</el-button>
				<el-button type="primary" @click="confirmUploadMyHonor">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Search from "@/components/Search";
	import {
		moralityByUserId,
		getHonor,
		getHonorDetail,
		getWeijiStudent,
		getWeijiDetail,
		addPersonalHonor,
		getHonorType,
		addHonor
	} from '@/api/teacher/quality/morality'
	export default {
		data() {
			return {
				imgSrc: this.imageSrc(),
				curRole: this.$store.getters.roles[0].roleKey,
				termName: "",
				title: "",
				open: false,
				isHonor: true,
				tableData: [],
				form: {},
				moralityForm: {},
				addMyHonorForm: {
					id: '',
					userName: this.$store.getters.name,
					studentCode: this.$store.getters.loginName,
					termName: '',
					honorName: '',
					resultLevel: '',
					indexRuleLevel: '',
					awardedUnit: '',
					bz: ''
				},
				upload: {
					action: '',
					accept: '.jpg,.png,.doc,.docx,.xls,.xlsx,.pdf,.txt',
					limit: 3,
					multiple: false,
					list: [],
					auto: false
				},
				constData: {
					honorLvlList: []
				},
				honorlist: [],
				weiJilist: [],
				termlist: [],
				dialog: {
					addMyHonor: false
				},
				addMyHonorFormRules: {
					honorName: [{
						required: true,
						message: "请输入荣誉名称",
						trigger: "blur"
					}, ],
					indexRuleLevel: [{
						required: true,
						message: "请选择荣誉级别",
						trigger: "change"
					}, ],
					awardedUnit: [{
						required: true,
						message: "请输入颁发单位",
						trigger: "blur"
					}, ]
				}
			}
		},
		components: {
			Search
		},
		created() {
			this.$store.dispatch('GetTermList').then(res => {
				this.termlist = res.data
				this.userId = this.$store.getters.userId
				this.termName = this.$store.getters.queryForm.termName
				this.addMyHonorForm.termName = this.$store.getters.queryForm.termName
				this.$refs.search.init(this.termName)
				this.getMorality()
				this.getHonorList()
				this.getWeijiList()
			})
			this.getHonorLvlDic()
		},
		methods: {
			// 审核状态
			aStatus(st) {
				return ['未审核', '审核通过', '审核驳回'][st]
			},
			// 荣誉级别字典
			getHonorLvlDic() {
				getHonorType({
					type: "personHonorlevel"
				}).then(res => {
					this.constData.honorLvlList = res.data
				})
			},
			// 打开上传个人荣誉
			uploadMyHonor() {
				this.addMyHonorForm.honorName = ''
				this.addMyHonorForm.indexRuleLevel = ''
				this.addMyHonorForm.awardedUnit = ''
				this.addMyHonorForm.resultLevel = ''
				this.addMyHonorForm.bz = ''
				this.addMyHonorForm.id = ''
				this.upload.list = []
				this.openDialog('addMyHonor')
			},
			// 打开弹窗
			openDialog(nm) {
				this.dialog[nm] = true
			},
			// 关闭弹窗
			closeDialog(nm) {
				this.dialog[nm] = false
			},
			// 确认上传个人荣誉
			confirmUploadMyHonor() {
				this.$refs.addMyHonorForm.validate(vld => {
					if (vld) {
						let fd = new FormData()
						fd.append('userName', this.addMyHonorForm.userName)
						fd.append('studentCode', this.addMyHonorForm.studentCode)
						fd.append('honorName', this.addMyHonorForm.honorName)
						fd.append('indexRuleLevel', this.addMyHonorForm.indexRuleLevel)
						fd.append('awardedUnit', this.addMyHonorForm.awardedUnit)
						fd.append('resultLevel', this.addMyHonorForm.resultLevel)
						if (this.addMyHonorForm.id) {
							fd.append('id', this.addMyHonorForm.id)
						}
						if (this.upload.list.length > 0) {
							this.upload.list.forEach(v => {
								fd.append('file', v)
							})
						}
						fd.append('bz', this.addMyHonorForm.bz)
						addHonor(fd).then(res => {
							this.$message.success(`个人荣誉${ res.message }`)
							this.closeDialog('addMyHonor')
							this.getHonorList()
						})
					} else {
						return false
					}
				})
			},
			// 材料上传
			uploadChange(file, fileList) {
				let rawTp = file.raw.type
				// pdf, excel表格, word文档, 图, 纯文本
				let allowTp = ['application/pdf', 'application/vnd.ms-excel',
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword', 'image/gif',
					'image/png', 'image/jpeg', 'text/plain'
				]
				if (allowTp.indexOf(rawTp) == -1) {
					this.$message.error("材料仅支持pdf、excel表格、word、图片与纯文本格式！")
					fileList.pop()
				} else {
					this.upload.list.push(file.raw)
					this.$message.success("上传成功")
				}
			},
			// 材料删除
			uploadRemove(file, fileList) {
				this.upload.list = fileList
			},
			// 审核驳回重新编辑上传
			reupload() {
				this.cancel()
				this.addMyHonorForm = Object.assign(this.addMyHonorForm, this.form)
				// 找到级别
				let lvl = 0
				this.constData.honorLvlList.forEach(v => {
					if (v.name == this.addMyHonorForm.indexRuleLevel) {
						lvl = v.code
					}
				})
				this.addMyHonorForm.indexRuleLevel = `${lvl}`
				this.upload.list = []
				this.openDialog('addMyHonor')
			},
			handleChange(data) {
				this.termName = data
				this.addMyHonorForm.termName = data
				this.getMorality()
				this.getHonorList()
				this.getWeijiList()
			},
			getMorality() {
				moralityByUserId({
					userId: this.userId,
					termName: this.termName
				}).then(res => {
					this.moralityForm = res.data
				})
			},
			getHonorList() {
				getHonor({
					userId: this.userId,
					termName: this.termName,
					pageSize: 100,
					pageNum: 1
				}).then(res => {
					let d = res.data.list
					d.forEach(v => {
						v._auditStatus = this.aStatus(v.auditStatus)
					})
					this.honorlist = d
				})
			},
			getWeijiList() {
				getWeijiStudent({
					userId: this.userId,
					termName: this.termName
				}).then(res => {
					this.weiJilist = res.data
				})
			},
			// 材料路径不是图片
			fileNotImg(url) {
				return [/\.xls$/, /\.xlsx$/, /\.doc$/, /\.docx$/, /\.pdf$/, /\.txt$/].some(v => v.test(url))
			},
			// 处理附件
			processAth(key) {
				let temp = key.split(",")
				let imgFileList = []
				let otherFileList = []
				// 材料按文件后缀名分流
				temp.forEach(v => {
					if (this.fileNotImg(v)) {
						otherFileList.push(this.imgSrc + v)
					} else {
						imgFileList.push(this.imgSrc + v)
					}
				})
				this.form._imgFileList = imgFileList
				this.form._otherFileList = otherFileList
				this.form._showImgList = imgFileList.length > 0
				this.form._showOtherFileList = otherFileList.length > 0
			},
			handleDetailHonor(row) {
				if (row.honorType == '个人荣誉') {
					this.title = '个人荣誉'
					this.open = true
					this.isHonor = true
					getHonorDetail({
						honorId: row.id,
						honorType: '0'
					}).then(res => {
						this.form = res.data
						this.addMyHonorForm.id = row.id
						if (this.form.proofFile) {
							this.processAth(this.form.proofFile)
						}
					})
				} else {
					this.title = '集体荣誉'
					this.open = true
					this.isHonor = true
					getHonorDetail({
						userId: this.userId,
						honorId: row.id,
						honorType: '1'
					}).then(res => {
						this.form = res.data
						if (this.form.proofFile) {
							this.processAth(this.form.proofFile)
						}
					})
				}
			},
			handleDetailWeiji(row) {
				this.title = "违纪记录";
				this.open = true;
				this.isHonor = false;
				getWeijiDetail({
					breakId: row.id
				}).then(res => {
					this.form = res.data
					if (this.form.proofFile) {
						this.processAth(this.form.proofFile)
					}
				})
			},
			cancel() {
				this.open = false
				this.resetForm('form')
			},
			tableHeaderColor({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				if (rowIndex === 0) {
					return "background-color: #F6F7FA;color: #91939A;font-weight: blod;text-align:center";
				}
			},
			cellStyleFun() {
				return "text-align:center";
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "@/assets/scss/studentResult.scss";

	.container {
		.user-nav {
			padding: 10px 12px;
		}
	}
</style>
<style scoped>
	.el-form-item {
		margin-bottom: 12px;
	}

	.el-form-item>>>.el-form-item__label {
		font-weight: bold;
		color: #303133;
	}

	.el-image {
		width: 100%;
	}
</style>